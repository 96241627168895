import { Controller } from "stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static targets = ['root', 'preview', 'input']

  connect() {
    console.log('FileUploadController::connect');
    this.url = this.data.get("url")
    this.submitButton = $(this.data.get("submit"))
    if (this.url == null) {
      this.url = "/entity_files"
    }

    console.log('FileUploadController::connect', this.previewTarget);
    console.log('FileUploadController::connect', this.rootTarget);
    console.log('FileUploadController::connect', this.inputTarget);
    console.log('FileUploadController::connect', this.submitButton);
    this.setupDropZone();
  }

  setupDropZone() {
    let myDropzone = new Dropzone( this.rootTarget, {
      url: this.url,
      paramName: "file", // The name that will be used to transfer the file
      maxFilesize: 10, // MB
      acceptedFiles: '.pdf,.png,.jpg,.doc,.docx',
      disablePreviews: false,
      previewsContainer: this.previewTarget,
      createImageThumbnails: false,
      dictFileTooBig: "El archivo es demasiado grande ({{filesize}}MiB). Tamaño máximo de archivo: {{maxFilesize}}MiB."
    });
    myDropzone.hiddenFileInput.removeAttribute('multiple');

    let pv = $(this.previewTarget)
    let self = this;
    let submitButton = $("input[name='commit']")
    myDropzone.on('addedfile', function(file) {
      if (submitButton.length > 0) {
        $(submitButton).prop('disabled', true);
      }
      pv.find('.dz-persisted').remove()
      if (this.files.length > 1) {
        console.log('FileUploadController::addedFile');
        this.removeAllFiles()
        this.addFile(file);
      }
    });
    myDropzone.on('complete', function(file) {
      if (submitButton.length > 0) {
        $(submitButton).prop('disabled', false);
      }

      let id = JSON.parse(file.xhr.response).id
      $(self.inputTarget).val('id:' + id);
    });
  }
}
