import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.profile_id = this.data.get("profileid");
    console.log("QueueInfoController::connect()", this.profile_id);

    document.addEventListener("Nomiri:QueueStatusUpdate", (e) => {
      this.update_queue_info(e.detail);
    });
  }
  //  {queue_id: 1, entries: {1 => available, 2 => available}  }
  update_queue_info(data) {
    console.log("QueueInfoController::update()", data);

    var list = $(this.element).find(`#queue_${data.queue_id}`).empty()
    var index = 1
    for( var row of data.entries ) {
      if (this.profile_id == row[2]) row[1] = 'self' 
      list.append(`<div class="queue--entries--entry ${row[1]}"><span>${index}</span></div>`)
      index += 1
    }
  }
}
