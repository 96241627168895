import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("TabsController::connect()");

    this.setup();
  }

  setup() {
    // TEMP -> De momento copy&paste de otro proyecto

    document.addEventListener("click", function(event) {

      const trigger = event.target;
      if (trigger.classList.contains("tabs--header--tab")) {
        let headerTabs;
        let bodyTabs;

        if (trigger.classList.contains("swiper-slide")) {
          headerTabs = trigger.parentNode.parentNode.parentNode.querySelectorAll(".tabs--header--tab");
          bodyTabs = trigger.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".tabs--body--tab");

          console.log(trigger.parentNode.parentNode.parentNode);
          console.log(trigger.parentNode.parentNode.parentNode.parentNode);
          console.log(bodyTabs);
        }
        else {
          headerTabs = trigger.parentNode.parentNode.querySelectorAll(".tabs--header--tab");
          bodyTabs = trigger.parentNode.parentNode.querySelectorAll(".tabs--body--tab");
        }


        for(let i = 0; i < headerTabs.length; i += 1) {
          if (headerTabs[i] == trigger) {
            headerTabs[i].classList.add("active");
            bodyTabs[i].classList.add("active");
          }
          else {
            headerTabs[i].classList.remove("active");
            bodyTabs[i].classList.remove("active");
          }
        };
      }
    });

    const tabss = document.querySelectorAll(".tabs");

    for (let tabs of tabss) {

      const header = tabs.querySelector(".tabs--header--tab")

      if (!tabs.querySelector(".active") && header != null) {
        header.classList.add("active");
        tabs.querySelector(".tabs--body--tab").classList.add("active");
      }
    }
  }
}
