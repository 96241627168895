require.context("../images", true);
import '@fortawesome/fontawesome-free/js/all'
import { Turbo } from "@hotwired/turbo-rails";

import "../src/frontend/frontend"

import '../../assets/builds/tailwind.css'
import 'preline/dist/preline.js'
import 'preline'

//Dropzone.autoDiscover = false;
