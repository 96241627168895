import "./javascript/filter";
import "./javascript/visible";
import "./javascript/dragscroll";
import "./cookies_modal/cookies_modal";
import "trumbowyg";
import "trumbowyg/dist/ui/trumbowyg.min.css";
import "trumbowyg/dist/ui/icons.svg";
import "select2"
import "select2/dist/css/select2.css";
import "croppie/croppie.css";


import "./javascript/"
import "./stylesheets/";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
const application = Application.start();

application.load(definitionsFromContext(
    require.context("./controllers", true, /\.js$/)
));
application.load(definitionsFromContext(
    require.context("../digivent/controllers", true, /\.js$/)
));
application.load(definitionsFromContext(
    require.context("../controllers", true, /\.js$/)
));


// Service worker
// Register the serviceWorker script at /serviceworker.js from your server if supported
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js')
  .then(function(reg) {
     console.log('Service worker change, registered the service worker');
  });
} else {
  console.error('Service worker is not supported in this browser');
}
