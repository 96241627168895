import { Controller } from "stimulus";
import { interval, Subject } from "rxjs";
import { debounce } from "rxjs/operators";

/**
 * Controller for the search and filter blocks. Every time a filter or the input of the search has been changed
 * the target block will be rendered and replaced.
 */
export default class extends Controller {
  static targets = ["search", "zip", "range"];

  connect() {
    console.log("SearchAndFilterController::connect()");
    
    this.search_url = this.data.get("search-url");
    this.target_selector = this.data.get("target-selector");
    
    console.log("SearchAndFilterController::connect()", this.search_url);
    console.log("SearchAndFilterController::connect()", this.target_selector);
    this.input = new Subject();
    this.input.pipe(debounce(() => interval(300))).subscribe( () => this.updateResults() );
  }

  // Called when the input has changed, passes to the rxjs debounce.
  inputChanged(e) {
    this.input.next();
  }

  // Called when a filter value has been changed
  selectChanged(e) {
    this.updateResults();
  }

  updateResults(save = false) {
    var formValues = $(".filter-display").serialize();

    if (formValues == "") {
      formValues = "reset_filter=1";
    }

    // We will make 2 request, first to get the filter result,
    // second to update the filter block itsself with the actual
    // filter_setting
    $.get(this.search_url, formValues, (data) => {
      // entities list have to output the count in a data attribute
      const total = $(data).find("[data-total]").data("total");
      const count = $(data).find("[data-total]").data("count");
      $(".status .total").html(total);
      $(".status .count").html(count);
      $(this.target_selector).html(data);

      this.activateLoadMore();
    });

    $.get(this.search_url, formValues, (data) => {
      $(this.element)
        .find(".active-filter-list")
        .html($(data).find(".active-filter-list").html());
    });
  }

  // Called when the filter button has been clicked.
  // Toggles the open class
  clickOpenFilter(e) {
    $(this.element).find("form.filter-display").toggleClass("open");
    //$("body").addClass("no-scroll");
    //document.body.style.top = `-${window.scrollY}px`;
    //document.body.style.position = "fixed";
  }

  // Called when the close button in the filter popup has been clicked.
  clickCloseFilter(e) {
    $(this.element).find("form.filter-display").removeClass("open");
    // When the modal is hidden, we want to remain at the top of the scroll position
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  // Click on show safe search, will display the safe search information
  clickShowSaveSearch() {
  }

  // Save the actual search for the current user and close the dialog
  clickSaveSearch() {
    this.updateResults(true);
    this.clickCloseFilter(null);
  }

  activateLoadMore() {
    const list = $(this.target_selector).find(".long-list")[0];
    if (list != null) {
      setupLoadMoreForList(list);
    }
  }
}
