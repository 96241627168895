import { Controller } from "stimulus";

export default class extends Controller {
  toggleMenu() {
    if (this.siteNavbar.classList.contains("open")) {
      this.siteNavbar.classList.remove("open");
    } else {
      this.siteNavbar.classList.add("open");
    }
  }

  connect() {
    console.log("NavbarController::connect");
    this.siteNavbar = document.querySelector(".site-navbar");
  }
}
