import { Controller } from "stimulus";

window.debounce = function(func, wait = 100) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export default class extends Controller {
  connect() {
    console.log("SliderController::connect()");

    this.setup();
  }

  setup() {
    const slider = this.element;
    const config = JSON.parse(slider.getAttribute("data-config"));
    let swiper;

    window.setTimeout(function() {
      swiper = new Swiper(slider, config);
    }, 120);

    // Image viewer

    const galleryViewer = slider.parentNode.querySelector(".gallery-viewer");

    if (galleryViewer) {
      const galleryViewerImg = galleryViewer.querySelector("img");
      const lastImageIndex = slider.querySelectorAll(".swiper-slide").length;

      document.addEventListener("click", debounce(function(event) {
        const trigger = event.target;

        if (trigger.classList.contains("gallery-viewer-img")) {
          let img = trigger.getAttribute("data-src") || trigger.getAttribute("src");
          let index = trigger.getAttribute("data-index");

          if (img) {
            galleryViewerImg.setAttribute("src", img);
            galleryViewerImg.setAttribute("data-index", index);
            galleryViewer.classList.add("visible");
          }
        }
        else if (trigger.classList.contains("gallery-viewer--prev") || trigger.classList.contains("gallery-viewer--next")) {
          event.preventDefault();

          let currentIndex = parseInt(galleryViewerImg.getAttribute("data-index"));
          let img, newImage;

          if (trigger.classList.contains("gallery-viewer--next")) {
            currentIndex = currentIndex + 1;

            if (currentIndex > lastImageIndex - 1) {
              currentIndex = 0;
            }
          }
          else {
            currentIndex = currentIndex - 1;

            if (currentIndex < 0) {
              currentIndex = lastImageIndex - 1;
            }
          }

          newImage = slider.querySelector(`.index-${currentIndex}`);
          img = newImage.getAttribute("data-src") || newImage.getAttribute("src");

          if (img) {
            galleryViewerImg.setAttribute("src", img);
            galleryViewerImg.setAttribute("data-index", currentIndex);
          }
        }
      }, 20));

      galleryViewer.querySelector(".close").addEventListener("click", function() {
        galleryViewerImg.setAttribute("src", "");
        galleryViewer.classList.remove("visible");
      });
    }
  }
}
