import { Controller } from "stimulus";
import { useChat } from "../../mixins/use_chat";
import { useAwsChime } from "../../mixins/use_aws_chime";
import { createConsumer } from "@rails/actioncable";

/**
 * Controller for the coffee room.
 */
export default class extends Controller {
  static targets = ["video", "modeSetup", "modeWaiting", "modeInterview"];

  connect() {
    console.log("InterviewController::connect()");

    useAwsChime(this);

    this.awsInit();
    this.awsActivatePreview();

    setInterval( () => {
        this.modeSetupTarget.classList.remove("active");
        this.modeWaitingTarget.classList.remove("active");
        this.modeInterviewTarget.classList.remove("active");
        
        this.modeWaitingTarget.classList.add("active");
    }, 3000);
    
  }

  disconnect() {
    console.log("InterviewController::disconnect() Closing AWS");
  }

  connectWebsocket() {
    this.consumer = createConsumer();
  }
}
