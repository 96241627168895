import { ConsoleLogger } from "amazon-chime-sdk-js";
import { Controller } from "stimulus";

/**
 * Controller for the favorites lists, use of filter and search
 */
export default class extends Controller {
  connect() {
    this.favorites = JSON.parse(this.data.get("favorites"));
    this.remove = this.data.get("remove") == "true"

    setTimeout(() => {
      this.setFavorites();
    }, 100);

    document.addEventListener("loadmore", (e) => {
      this.setFavorites();
    });
  }

  setFavorites() {
    this.favorites.forEach((item) => {
      const bookmarkElement = $(`.${item} .fa-bookmark`);
      if (!bookmarkElement.hasClass("changed")) {
        bookmarkElement.addClass("voted");
      }
    });
  }

  toggleFav(event) {
    event.preventDefault();

    const bookmarkElement = $(event.currentTarget).find(".fa-bookmark");
    const marked = bookmarkElement.hasClass("voted") ? 0 : 1;
    console.log("r", marked);
    bookmarkElement.addClass("changed");

    if (marked == 1) {
      bookmarkElement.addClass("voted");
    } else {
      if (this.remove) {
        bookmarkElement.closest(".fav-element").remove();
      } else {
        bookmarkElement.removeClass("voted");
      }
    }

    // Make the request, result is a boolean only?
    $.get($(event.currentTarget).attr("href") + "&r=" + marked, (data) => {
      // console.log(data)
    });
  }
}
