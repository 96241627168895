import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["url","notValid"];

  regexp_url = /https?:\/\/(?:www.)?(?:(vimeo).com\/(.*)|youtu.be\/.*|youtube.com\/watch\?v=.*)/

  connect() {
    console.log("VideoGalleryController::connect()");
    this.setup();
  }

  setup() {
  }

  /** validate the url in the input and add it as new element if valid */
  add() {
    console.log("VideoGalleryController::add()", this.urlTarget.value);

    if (!this.regexp_url.test(this.urlTarget.value)) {
      console.log("not_valid");
      this.notValidTarget.classList.add("show");
      return;
    }
    this.notValidTarget.classList.remove("show");

    const content = $(this.element).find("template").html();
    // const tid = Date.now() + Math.floor(Math.random() * 10000);
    // const newElement = $(content.replaceAll("_rnd_", tid));

    const newElement = $(content);
    console.log(newElement);
    console.log(newElement.find("input"));
    newElement.find("input").val(this.urlTarget.value);
    $(this.element).find(".videos").append(newElement);

  }
}
