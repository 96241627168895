import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("GalleryController::connect()");
    this.setup();
  }

  setup() {
    const gallery = this.element;

    gallery.parentNode.querySelector(".add-gallery-image").addEventListener("click", function(event) {
      const self = $(event.currentTarget);

      const content = self.parent().find("template").html();
      const tid = Date.now() + Math.floor(Math.random() * 10000);
      const newElement = $(content.replaceAll("_rnd_", tid));
      const fileElement = newElement.find("#entity_gallery_image");
      const imgElement = newElement.find("img");

      fileElement.trigger("click");

      fileElement.on("change", () => {
        if (fileElement[0].files && fileElement[0].files[0]) {
          var reader = new FileReader();

          reader.onload = (event) => {
            imgElement.attr("src", event.target.result);
            self.parent().find(".images").append(newElement);

            const imgsCounter = self.parent().find(".images .gallery-image:not(.to-remove)").length;

            if (imgsCounter >= 10) {
              self.parent().addClass("max");
            }
            else {
              self.parent().removeClass("max");
            }
          };

          reader.readAsDataURL(fileElement[0].files[0]);
        } else {
          alert("Sorry - you're browser doesn't support the FileReader API");
        }
      });
    });

    gallery.addEventListener("click", function(event) {
      const trigger = event.target;

      if (trigger.classList.contains("remove-trigger")) {
        console.log("test");

        const parent = trigger.parentNode.parentNode;
        const input = trigger.querySelector(".destroy-image");

        parent.classList.toggle("to-remove");

        input.checked = true;
        const images = parent.parentNode.querySelectorAll(".images .gallery-image:not(.to-remove)");

        if (images.length < 10) {
          parent.parentNode.parentNode.classList.remove("max");
        }
        else {
          if (images.length > 10) images[images.length - 1].remove();

          parent.parentNode.parentNode.classList.add("max");
        }
      }
    });
  }
}
