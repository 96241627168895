$(function () {

    //
    $(".if-set-filter").click(function(e){
        e.preventDefault();

        console.log("IF: click");
        $(".if-filter-bar .if-set-filter").removeClass("if-active")
        $(this).addClass("if-active")
        
        var filter = $(this).data('if-filter');
        $(".if-filtered").each(function(index,element){
            var property = $(this).data('if-property');
            if(filter==property) {
                $(this).show()
            } else { 
                $(this).hide()
            }
        });
    });

    $(".if-filter-bar .if-set-filter").first().click();
});