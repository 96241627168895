import { Controller } from "stimulus";

/**
 * Controller for a select to visit the location stored in the option value on change.
 */
export default class extends Controller {
  connect() {
    console.log("LinkCombo::connect");

    this.element.onchange = function () {
      window.location.href = this.value;
    };
  }
}
