/** Mixing to controll the layouts in the conferences
 *
 * params: controller, element to be used
 */
export const useTileLayout = (controller) => {
  Object.assign(controller, {

    tileLayoutInit(gridElement) {
      this.gridElement = gridElement;

      console.log("TileLayout::Init()", gridElement);
      this.layout = 1;
      this.moveTiles();
      this.calculateGrid(gridElement);
    },

    switchLayout(layout) {
      this.layout = layout;
      this.moveTiles();
      this.calculateGrid(this.gridElement);
    },

    switchLayout0() {
      this.switchLayout(0);
    },
    
    switchLayout1() {
      this.switchLayout(1);
    },

    // Move all the tiles to the correct parent, depending on the layout
    moveTiles() {

      if ( this.layout == 0) {
        $(".thumb-grid .video-tile").appendTo(".main-grid");
        $(".thumb-grid").css("display","none");
        return;
      }
      
      $(".thumb-grid").css("display","flex");
      $(".main-grid .video-tile.participant-guest").appendTo(".thumb-grid");
    },

    calculateGrid() {
      const gridElement = $(this.gridElement).find(".main-grid");
      const tiles = $(gridElement).find(".video-tile").length

      console.log("TileLayout::tiles", tiles);
      const ht = gridElement.height();
      const wt = gridElement.width();
      const rt = wt / ht;
      console.log(`TileLayout: total ${wt}x${ht}`);

      var best_rows = 0;
      var best_ratio = 1000;
      for (let rows = 1; rows <= tiles; rows++) {
        var cols = Math.ceil(tiles / rows);
        var gr = (cols * 1.333) / rows;

        if (Math.abs(gr - rt) < best_ratio) {
          best_ratio = Math.abs(gr - rt);
          best_rows = rows;
        }
      }

      // console.log("TileLayout: best_rows", best_rows)
      cols = Math.ceil(tiles / best_rows);
      // console.log("TileLayout: cols", cols)

      var rg = (cols * 1.333) / best_rows;
      console.log("Screen ratio, grid ratio", rt, rg);
      // Check if we need max with or max height
      if (rg > rt) {
        this.tileWidth = wt / cols;
        this.tileHeight = this.tileWidth / 1.333;
      } else {
        this.tileHeight = ht  / best_rows;
        this.tileWidth = this.tileHeight * 1.333;
      }

      gridElement.find(".video-tile").css("flex", "0 0 " + this.tileWidth + "px");
      gridElement.find(".video-tile").css("height", this.tileHeight + "px");
    },

    createTile(id) {
      console.log("TileLayout::createTile()", id);

      const clone = $(this.gridElement.find("template").html());
      const targetContainer = $(this.gridElement).find(".main-grid");

      clone.attr("id", id);
      targetContainer.append(clone);
    }
  });
};
