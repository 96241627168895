import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.setup();
  }

  setup() {
    const formFile = $(this.element).find(".form-control-file");

    if (formFile.length === 0) {
      return;
    }

    formFile[0].addEventListener("change", function(e) {
      const fullPath = this.value;

      const startIndex = fullPath.indexOf("\\") >= 0 ? fullPath.lastIndexOf("\\") : fullPath.lastIndexOf("/");

      let filename = fullPath.substring(startIndex);

      if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
        filename = filename.substring(1);
      }

      this.parentNode.parentNode.querySelector(".new-file").textContent = filename;
    });
  }
}
