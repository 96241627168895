import { Controller } from "stimulus";

/**
 * Controller for the style editor.
 */
export default class extends Controller {
  connect() {
    console.log("StyleController()");

    $(".color-input").each(function () {
      console.log($(this).val());
      $(this).spectrum({
        preferredFormat: "hex",
        showInput: true,
        move: function (color) {
          const c = color.toHexString();
          console.log("MOVE", c);
          document.documentElement.style.setProperty(
            "--" + $(this).data("color-name"),
            c
          );
        },
      });
    });
  }

  style_changed(event) {
    console.log("StyleController::style_changed()");
    console.log(event);
    $("#template").attr("href", event.target.value);
  }
}
