import { Controller } from "stimulus";
import {
  ConsoleLogger,
  DefaultDeviceController,
  LogLevel,
} from "amazon-chime-sdk-js";

/**
 * ManageInterviews controller
 *
 */
export default class extends Controller {
  static targets = ["audioInSelect", "videoSelect"];

  connect() {
    console.log("ManageInterviewsController::connect");
    this.element[this.identifier] = this;

    document.addEventListener("Nomiri:CloseModal", () => {
      if (this.deviceController != null) {
        this.deviceController.stopVideoPreviewForVideoInput(
          $("video#video")[0]
        );
      }
    });

  }

  async prepareVideoSetup() {
    this.logger = new ConsoleLogger("MyLogger", LogLevel.DEBUG);

    //Initialize DefaultDeviceController
    this.deviceController = new DefaultDeviceController(this.logger, {
      enableWebAudio: true,
    });

    //List the video device list
    const videoInputDevices = await this.deviceController.listVideoInputDevices();
    if (videoInputDevices.length == 0) {
      return false;
    }
    videoInputDevices.forEach((device) => {
      this.videoSelectTarget.options.add(
        new Option(device.label, device.deviceId)
      );
    });

    const audioInputDevices = await this.deviceController.listAudioInputDevices();
    audioInputDevices.forEach((device) => {
      console.log("useAws::device in", device);
      this.audioInSelectTarget.options.add(
        new Option(device.label, device.deviceId)
      );
    });
    $(this.audioInSelectTarget).selectedIndex = 0;



    //Choose video device
    await this.deviceController.chooseVideoInputDevice(videoInputDevices[0].deviceId);

    //Grab the video element
    const videoElement = $("video#video")[0];

    //Start video preview
    this.deviceController.startVideoPreviewForVideoInput(videoElement);
  }

  toggleSetup() {
    console.log("ManageInterviewsController::toggleSetup");

    var setup = $(".video-setup");
    this.prepareVideoSetup().then(() => {
      setup.toggleClass("shown");
    });
  }
}
