import { Controller } from "stimulus";
import { useChat } from "../mixins/use_chat";
import consumer from '../../channels/consumer';

export default class extends Controller {
    static targets = [
        "chat",
        "chatInput",
        "chatSend",
    ];

    connect() {
        console.log("ChatController::connect");
        const options = {
            targetChat: this.chatTarget,
            input: this.chatInputTarget,
            token: this.data.get("token"),
            connect_as: this.data.get("connect-as"),
        };

        this.chat = useChat(this, consumer, options);
    }

    disconnect() {
        console.log("ChatController::disconnect");
    }
}
