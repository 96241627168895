import { Controller } from "stimulus";
import { createConsumer } from "@rails/actioncable";

/**
 * Controller for user queue info
 * - can subscribe to push notification
 */
export default class extends Controller {
  connect() {
    console.log("QueueInfoController::connect id", this.identifier);
    this.element[this.identifier] = this;

    this.profile_id = this.data.get("profile");
    this.connectWebsocket(this.data.get("profile"));

    this.checkPushNotificationPermissions();
     navigator.serviceWorker.addEventListener('message', function(event) {
        console.log("Got reply from service worker: " + event.data);
    });
  }

  checkPushNotificationPermissions() {
    if ('Notification' in window && navigator.serviceWorker) {
      console.log("QueueInfoController::checkPushNotificationPermissions", Notification.permission);

      if (Notification.permission === "default") {
        $(".ask-push-permission").removeClass("d-none");
      }
      if (Notification.permission === "granted") {
        this.subscribePushNotification();
      }

    }
  }

  disconnect() {
    this.consumer.disconnect();
  }

  connectWebsocket(connect_as) {
    console.log("QueueInfoController::connectWebsocket connect as", connect_as);
    this.consumer = createConsumer();

    this.subscribeGlobalChannel(connect_as);
  }

  subscribeGlobalChannel(connect_as) {
    this.globalChannel = this.consumer.subscriptions.create(
      { channel: "GlobalChannel", connect_as: connect_as },
      {
        received: (data) => {
          if (data.queue_status) {
            this.onQueueStatus(data.queue_status);
          }
        },
      }
    );
  }

  onQueueStatus(data) {
    console.log("QueueInfoController::onQueueStatus", data);

    var list = $(this.element).find(`#queue_${data.queue_id}`).empty();
    var i=1;
    for (var row of data.entries) {
      if (this.profile_id == row[2]) row[1] = "self";
      list.append(`<div class="queue-entry-status ${row[1]}">${i}</div>`);
      i++;
    }
  }

  askPushPermission() {
    window.Notification.requestPermission().then((subscription) => {
      if (subscription) {
        console.log("QueueInfoController::askPushPermission", subscription);
        $(".ask-push-permission").addClass("d-none");
        this.subscribePushNotification();
        return;
      }
    });
  }

  subscribePushNotification() {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
        console.log("QueueInfoController::subscribe", subscription);
        var pathname = window.location.pathname;
        $.ajax({
          url: pathname,
          type: "POST",
          data: JSON.stringify({ subscription: subscription }),
          contentType: "application/json",
          dataType: "json",
          success: (data) => {
            console.log("QueueInfoController::subscribe success", data);
          },
          error: (data) => {
            console.log("QueueInfoController::subscribe error", data);
          },
        });
        if (!subscription) {
          return serviceWorkerRegistration.pushManager.subscribe(
            { userVisibleOnly: true,
              applicationServerKey: "BKTinvnPoRxaZKLC4ZFBapdkKDUhBoQSgD7-ZnT3TV_-3SMgvndpTHVBxHU-mxveAX9QMnb8Fre8WA_ABz7mxck"
            }).then((newSubscription) => {
            console.log("QueueInfoController::subscribe", newSubscription);
          });
        } else {
          return subscription;
        }
      });
    });
  }
}
