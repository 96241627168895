import { Controller } from "stimulus";

/**
 * Connects to a broadcast which is in preview to active it on the correct time
 */
export default class extends Controller {
  connect() {
    console.log("Broadcasts::connect");

    // this.activateTime = this.data.get("activate") * 1000;
    // this.url = this.data.get("url");
    // if ( this.checkTime() === false) {
    //   this.checkInterval = setInterval( ()=> this.checkTime(), 1000);
    // }
    // this.activate();
  }

  checkTime() {
    if ( Date.now() > this.activateTime ) {
      this.activate();
      return true;
    }
    return false;
  }

  activate() {
    if (this.checkInterval != null) {
      clearInterval(this.checkInterval);
      this.checkInterval = null;
    }

    $(this.element).find(".preview").remove();
    $(this.element).append(`<div id="main_bc" class="auto-video" data-video=${this.url}></iframe>`);
    window.onYouTubeIframeAPIReady();
  }

  enter() {
    $("body").toggleClass("direct-frame");
  }






}
