import { Controller } from "stimulus";

/**
 * Controller for the coffee room.
 */
export default class extends Controller {
  static targets = [];

  connect() {
    console.log("PaymentController::connect()");
    this.stripe = Stripe(this.data.get("key"));
  }

  actionBuyTicket(event) {
    event.preventDefault();
    const sessionUrl = $(event.currentTarget).data("url");
    console.log("PaymentController::actionBuyTicket()", sessionUrl);

    fetch(sessionUrl, {
      method: "POST",
    })
      .then( (response) => {
        console.log("PaymentController::actionBuyTicket() response", response);
        return response.json();
      })

      .then( (session) => {
        console.log("PaymentController::actionBuyTicket() session", session);
        return this.stripe.redirectToCheckout({ sessionId: session.id });
      })

      .then( (result) => {
        // If redirectToCheckout fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using error.message.
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  }

  disconnect() {
    console.log("PaymentController::disconnect()");
  }
}
