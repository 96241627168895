import { Controller } from "stimulus";
import { useChat } from "../../mixins/use_chat";
import { useAwsChime } from "../../mixins/use_aws_chime";
import { useTileLayout } from "../../mixins/use_tile_layout";
import consumer from '../../../channels/consumer';

/**
 * Frontend Controller for conference.
 *
 * Uses use_aws and use_chat to connect to a conference.
 */
export default class extends Controller {
  static targets = [
    "audio",
    "audioInSelect",
    "chat",
    "chatInput",
    "chatSend",
    "conferenceView",
    "popupCloseMeeting",
    "popupInvite",
    "popupMeetingEnd",
    "popupNoVideo",
    "setupView",
    "video",
    "videoSelect",
    "videoTemplate",
  ];

  connect() {
    const token = this.data.get("token");
    if (!token) {
      console.log(
        "ConferenceController::connect() no token, not connecting"
      );

      return;
    }
    console.log(
      "ConferenceController::connect() for token: " + this.data.get("token")
    );


    if (this.hasChatTarget) {
      this.participantId = this.data.get("participant");
      this.setupChat();
    }

    if (this.data.get("meeting") && this.data.get("meeting") != "null") {
      this.setupVideoConference();
    } else {
      this.element.classList.add("status-online");
    }

    // Create a new tile
    document.addEventListener("Nomiri:NewParticpant", (e) => {
      if (this.closeTimer != null) {
        clearTimeout(this.closeTimer);
        this.closeTimer = null;
      }
      console.log("ConferenceController::connect() Nomiri:NewParticpant", e.detail);
      this.createTile(e.detail.id);
      this.refreshQueue();
    });

    document.addEventListener("Nomiri:Binding", (e) => {
      console.log("ConferenceController::connect Nomiri:Binding");
    });

    var ctrl = this;
    document.addEventListener("Nomiri:RemoveParticipant", (e) => {
      console.log("ConferenceController::connect() Nomiri:RemoveParticipant", e.detail);
      $(`#p_${e.detail}`).closest(".video-tile").remove();
      ctrl.tileLayoutInit($(".video-grid"));
      ctrl.refreshQueue();
    });

    document.addEventListener("Nomiri:UserSelect", (e) => {
      console.log("ConferenceController::connect() Nomiri:UserSelect", e.detail);
      this.inviteUser(e.detail);
    });

    document.addEventListener("Nomiri:CloseConference", (e) => {
      console.log("ConferenceController::connect() Nomiri:CloseConference");
      Turbo.visit("/");
    });

    document.addEventListener("Nomiri:RefreshConference", (e) => {
      console.log("ConferenceController::RefreshConference()");
      $.get(`${this.baseUrl}/slot/refresh`, (data) => {
        console.log("ConferenceController::connect()()", data);
        this.showSlotStatus(data);
        this.refreshQueue();
      });
    });

    document.addEventListener("Nomiri:UpdateQueue", (e) => {
      console.log("ConferenceController::UpdateQueue()");
      this.updateQueue(e.detail);
    });

    document.addEventListener("Nomiri:PermissionDenied", (e) => {
      console.log("ConferenceController::PermissionDenied()");
      this.onNoVideo();
    });

    document.addEventListener("Nomiri:MeetingEnded", (e) => {
      console.log("ConferenceController::MeetingEnded()");
      this.clickReload();
    });
  }

  setupChat() {
    const options = {
      targetChat: this.chatTarget,
      input: this.chatInputTarget,
      token: this.data.get("token"),
      connect_as: this.data.get("connect-as"),
      participantId: this.participantId,
      admin: this.admin,
    };

    this.chat = useChat(this, consumer, options);
  }

  setupVideoConference() {
      console.log("ConferenceController::connect() Connecting AWS");
      useAwsChime(this);
      useTileLayout(this);
      this.connectAwsMeeting();

      setTimeout(() => {
        this.tileLayoutInit($(".video-grid"));
      }, 100);

      this.video = true;
  }

  disconnect() {
    console.log("ConferenceController::disconnect()");
    if (this.video) {
      this.disconnectConference(this.session);
    }

    if (this.hasChatTarget) {
      consumer.subscriptions.remove(this.chatChannel);
    }
  }

  toggleSetup() {
    var setup = $(this.setupViewTarget);
    this.prepareVideoSetup();
    setup.toggleClass("shown");
  }

  /**
   * Called from use_aws when a tile has been changed
   */
  onTileChange() {
    this.calculateGrid();
  }

  /**
   * Called from use_aws when the meeting has been ended
   */
  onVideoDidStopChange() {
    $(this.popupMeetingEndTarget).addClass("shown");
    this.disconnectConference(this.session);
  }

  /**
   * Called from use_aws when no video device has been found.
   */
  onNoVideo() {
    console.error("ConferenceController::onNoVideo");
    $(this.popupNoVideoTarget).addClass("shown");
  }

  /**
   * Called when no device at all has been found, user can join
   * chat only.
   */
  onNoAudioVideo() {
    console.error("ConferenceController::onNoAudioVideo");
    $(this.popupNoVideoTarget).addClass("shown");
  }

  /**
   * Called from use_aws when an un excepted error has been called.
   */
  onError(error) {
    console.error("ConferenceController::onError", e);
  }

  /**
   * User clicked on mute button, fowards to use_aws and change class of the button
   * to change icon.
   */
  clickMute(element) {
    console.log("clickMute()");
    $(element.currentTarget).toggleClass("active");

    if ($(element.currentTarget).hasClass("active")) {
      this.muteAudio();
    } else {
      this.unMuteAudio();
    }
  }

  clickBlind(element) {
    console.log("ConferenceController::clickBlind()");
    $(element.currentTarget).toggleClass("active");

    if ($(element.currentTarget).hasClass("active")) {
      this.hideVideo();
    } else {
      this.showVideo().then();
    }
  }

  clickRaiseHand(element) {
    console.log("clickRaiseHans()");
    $(element.currentTarget).toggleClass("active");
  }

  clickExit(element) {}

  // Open the modal to invite another user into the meeting
  clickInvite() {
    $(this.popupInviteTarget).toggleClass("shown");
    document.dispatchEvent(new CustomEvent("Nomiri:RefreshUsersOnline"));
  }

  clickKickParticipant(event) {
    this.kickUser($(event.currentTarget).data("pid"));
  }

  clickReload() {
    var url = window.location.href;
    if (url.searchParams) {
      url.searchParams.append("r", 1);
      window.location.href = url;
    } else {
      window.location.href = window.location.href + "?r=1";
    }
  }

  clickExit() {
    console.log("ConferenceController::clickExit()");
    this.actionQuit();
  }

  /**
   * Called from the selectbox when a different camera has been choosen
   */
  cameraChanged() {
    console.log("cameraChanged()");
  }

  /**
   * Called from the selectbox when a different microphone has been choosen
   */
  audioInChanged() {
    console.log("audioInChanged()");
  }

  inviteUser(user_id) {
    console.log("***", `${this.baseUrl}/invite/${user_id}`);
    $.get(`${this.baseUrl}/invite/${user_id}`, (data) => {
      // this.searchResultTarget.innerHTML = data;
    });
  }

  kickUser(participant_id) {
    $.get(`${this.baseUrl}/kick/${participant_id}`, (data) => {
      // this.searchResultTarget.innerHTML = data;
    });
  }

  actionQuit() {
    Turbo.visit(`${this.baseUrl}/exit`);
  }
}
