import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("DropzoneController::connect()");
    this.setup();
  }

  setup() {
    $(this.element).dropzone({
      acceptedFiles: 'image/*,application/pdf',
      dictDefaultMessage: 'Drop your PDFs here'
    });
  
  }
}
