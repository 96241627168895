import { Controller } from "stimulus";
/**
 * Controller for a multiple select entity_type_input
 *
 * - Check min y max
 */
export default class extends Controller {
  connect() {
    console.log("MultiSelectInputController::connect()");

    this.min = this.data.get("min");
    this.max = this.data.get("max");
    this.name = this.data.get("name");

    this.setupValidation();
  }

  setupValidation() {
    $(`[name='entity[${this.name}][]']`).on("change", () => {
      if (this.min) this.checkMin();
      if (this.max) this.checkMax();
    });
    if (this.min) this.checkMin();
    if (this.max) this.checkMax();
  }

  /**
   * If the actual count of checked boxes is lower then this.min sets all checkboxes to required.
   */
  checkMin() {
    const count = this.countChecked();

    this.checkboxes().attr("required", count < this.min);
  }

  /** If the actual count of checked is equals or higher than this.max, all checkboxes are disabled */
  checkMax() {
    const count = this.countChecked();
    this.checkboxes().attr("disabled", false);

    if (count >= this.max) {
      this.unchecked().attr("disabled", true);
    }
  }

  checkboxes() {
    return $(`[name='entity[${this.name}][]']`);
  }

  checked() {
    return $(`[name='entity[${this.name}][]']:checked`);
  }
  unchecked() {
    return $(`[name='entity[${this.name}][]']:not(:checked)`);
  }

  countChecked() {
    return this.checked().length;
  }
}
