import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("AgendaController::connect()");

    this.setup();
  }

  setup() {
    // agenda
    const agenda = document.querySelector(".agenda");

    if (agenda) {
      const days = document.querySelectorAll(".agenda--header--day");
      // const entries = document.querySelectorAll(".agenda--entries");

      agenda.addEventListener("click", function (event) {
        const trigger = event.target;

        if (trigger.classList.contains("agenda--header--day")) {
          const dayEntriesId = trigger.getAttribute("data-entries-id");

          for (let day of days) {
            const entriesId = day.getAttribute("data-entries-id");
            const dayEntries = document.querySelector(`#${entriesId}`);

            if (entriesId != dayEntriesId) {
              day.classList.remove("active");
              dayEntries.classList.remove("active");

            } else {
              day.classList.add("active");
              dayEntries.classList.add("active");
            }
          }
        }
      });

      days[0].click();
    }
  }
}
