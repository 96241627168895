import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration
} from "amazon-chime-sdk-js";

/** Mixing to be used in controller to connect to a amazon chime ask meeting
 *
 *  data-meeting
 *  data-attendee
 *
 * methods called back in the main controller
 *
 * - onVideoDidStopChange
 * - onNoVideo
 * - onError
 * 
 * Events dispatched from this class:
 * 
 * Nomiri:PermissionDenied
 * Nomiri:NoVideoDevice
 * 
 * Internal status:
 * 
 * status:
 * -1: Setup failed due missing permission or camera, needs reinit
 *  0: Initializing
 *  1: Ready
 */
export const useAwsChime = (controller) => {

  Object.assign(controller, {
    awsInit() {
    },

    showDeviceList(target, devices) {
      if (!target) {
        console.error("Target must not be null");
        return
      }
      devices.forEach((device) => {
        console.log("useAws::device", device);
        const label = device.label.substring(0, device.label.indexOf("("));
        target.options.add(
          new Option(label, device.deviceId)
        );
      });
      $(this.target).selectedIndex = 0;
    },

    awsActivatePreview(target) {
      console.log("useAws::awsActivatePreview", target);
      if (target == null) {
        console.error('Target must not be null');
        return;
      }
      this.target = target;

      const logger = new ConsoleLogger("AwsLogger", LogLevel.INFO);
      this.deviceController = new DefaultDeviceController(logger, {
        enableWebAudio: true,
      });

      this.deviceController.listVideoInputDevices().then((deviceList) => {
        this.videoInputDevices = deviceList;
        this.selectedVideoInputDevice = localStorage.getItem("preferredVideoInputDevice") || 0;

        if (this.videoSelectTarget) {
          this.showDeviceList(this.videoSelectTarget, deviceList);
        }
        console.log("useAws::awsActivatePreview", deviceList[this.selectedVideoInputDevice].deviceId);

        this.deviceController
            .chooseVideoInputDevice(deviceList[this.selectedVideoInputDevice].deviceId)
          .then(() => {
            this.deviceController.startVideoPreviewForVideoInput(
              target
            );
          })
          .catch((error) => {
            console.error(error);
            document.dispatchEvent(new CustomEvent("Nomiri:PermissionDenied"));
          });
      }).catch((error) => {
        console.error("NO DEVICE", error);
        document.dispatchEvent(new CustomEvent("Nomiri:NoVideoDevice"));
      });

      this.deviceController.listAudioInputDevices().then((deviceList) => {
        if (this.audioInputSelectTarget) {
          this.showDeviceList(this.audioInSelectTarget, deviceList);
        }
        this.deviceController.chooseAudioInputDevice(deviceList[0]).then();
      });

      this.deviceController.listAudioOutputDevices().then((deviceList) => {
        if (this.audioOutSelectTarget) {
          this.showDeviceList(this.audioOutSelectTarget, deviceList);
        }
        this.deviceController.chooseAudioOutputDevice(deviceList[0]).then();
      });
    },

    awsStopPreview() {
      if (this.deviceController == null) {
        return;
      }
      this.deviceController.chooseAudioInputDevice(null);
      this.deviceController.stopVideoPreviewForVideoInput(
        controller.videoTarget
      );
    },

    async connectAwsMeeting() {
      this.element.classList.add("status-connecting");
      this.meeting = JSON.parse(this.data.get("meeting"));
      this.attendee = JSON.parse(this.data.get("attendee"));

      if (this.meeting == null || this.attendee == null) {
        console.error("data-meeting and data-attendee must be set!");
        return;
      }

      this.session = await this.setupSession();
      console.log("useAws::SESSION", this.session);

      const video_success = await this.setupVideo(this.session);
      if (video_success) {
        console.log("useAws::SESSION", video_success);
        await this.setupAudio(this.session);
        await this.connectConference(this.session);

        this.element.classList.remove("status-connecting");
        this.element.classList.add("status-online");
      } else {
        this.onNoVideo();
      }
    },

    async setupSession() {
      try {
        controller.logger = new ConsoleLogger("AwsLogger", LogLevel.INFO);
        const deviceController = new DefaultDeviceController(controller.logger);

        // You need responses from server-side Chime API. See below for details.
        const meetingResponse = this.meeting;
        const attendeeResponse = this.attendee;
        const configuration = new MeetingSessionConfiguration(
          meetingResponse,
          attendeeResponse
        );
        // In the usage examples below, you will use this meetingSession object.
        return new DefaultMeetingSession(
          configuration,
          controller.logger,
          deviceController
        );
      } catch (exception) {
        console.error("useAws::FETCHED ERROR", exception);
      }
    },

    /**
     * Setup the local video device, return true if success
     * @param {*} session
     */
    async setupVideo(session) {
      try {
        const videoInputDevices = await session.audioVideo.listVideoInputDevices();

        if (videoInputDevices.length == 0) {
          console.error("useAws::NO VIDEO DEVICE");
          return false;
        }

        //this.showVideoDeviceList(videoInputDevices);
        await session.audioVideo.chooseVideoInputDevice(
          videoInputDevices[0].deviceId
        );

        session.audioVideo.startLocalVideoTile();
        return true;
      } catch (error) {
        console.error(error);
        document.dispatchEvent(new CustomEvent("Nomiri:PermissionDenied"));
      }
    },

    async setupAudio(session) {
      const audioInputDevices = await session.audioVideo.listAudioInputDevices();
      audioInputDevices.forEach((device) => {
        this.audioInSelectTarget.options.add(
          new Option(device.label, device.deviceId)
        );
      });
      $(this.audioInSelectTarget).selectedIndex = 0;

      if (audioInputDevices.length > 0) {
        await session.audioVideo.chooseAudioInputDevice(
          audioInputDevices[0].deviceId
        );
      } else {
        console.error("NO INPUT AUDIO DEVICE FOUND");
      }

      session.audioVideo.bindAudioElement(this.audioTarget).then();
    },

    async connectConference(session) {
      const observer = {
        // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
        videoTileDidUpdate: (tileState) => {
          if (!tileState.boundAttendeeId) {
            console.log("useAws::videoTileDidUpdate NO TILE", tileState);
            return;
          }
          console.log("useAws::videoTileDidUpdate", tileState);

          //session.audioVideo.bindAudioElement(this.audioTarget).then();

          var videoElement = document.getElementById(tileState.boundExternalUserId).getElementsByTagName("video")[0];
          session.audioVideo.bindVideoElement(tileState.tileId, videoElement);
          //document.dispatchEvent(new CustomEvent("Nomiri:Binding"));
        },
        audioVideoDidStart: () => {
          console.log("useAws::audioVideoDidStart");
        },

        audioVideoDidStop: (sessionStatus) => {
          // See the "Stopping a session" section for details.
          console.log(
            "useAws::Stopped with a session status code: ",
            sessionStatus
          );
          document.dispatchEvent(new CustomEvent("Nomiri:MeetingEnded"));
        },
      };
      session.audioVideo.addObserver(observer);
      session.audioVideo.start();
      session.audioVideo.startLocalVideoTile();
      console.log("useAws::connectConference connected");
    },

    muteAudio() {
      this.session.audioVideo.realtimeMuteLocalAudio();
    },

    unMuteAudio() {
      this.session.audioVideo.realtimeUnmuteLocalAudio();
    },

    hideVideo() {
      this.session.audioVideo.stopLocalVideoTile();
      this.session.audioVideo.chooseVideoInputDevice(null);
    },

    toggleAudio() {
      if (this.session.audioVideo.realtimeCanUnmuteLocalAudio()) {
        this.unMuteAudio();
      } else {
        this.muteAudio();
      }
    },

    toggleVideo() {
    },

    joinConference() {
      this.awsStopPreview();
      HSOverlay.close(document.getElementById('video-setup'));

      setTimeout(() => {
        this.connectAwsMeeting();
      }, 2500);
    },

    async showVideo() {
      console.log("useAws::showVideo");
      const videoInputDevices = await this.session.audioVideo.listVideoInputDevices();

      await this.session.audioVideo.chooseVideoInputDevice(
        videoInputDevices[0].deviceId
      );

      this.session.audioVideo.startLocalVideoTile();
    },

    // Bind the local video to the setup window
    prepareVideoSetup() {
      var tile = this.session.audioVideo.getLocalVideoTile();
      console.log("useAws::actionOpenSetup", tile);
      tile.bindVideoElement(this.videoTarget);
    },

    disconnectConference(session) {
      session.audioVideo.chooseVideoInputDevice(null);
      session.audioVideo.chooseAudioInputDevice(null);
      session.audioVideo.stopLocalVideoTile();
      session.audioVideo.stopVideoPreviewForVideoInput(this.videoTarget);
    },

    async cameraChanged(event) {
      const deviceId = $(event.currentTarget).val();

      console.log("useAws::cameraChanged() index:", deviceId);
      if (this.session) {
        await this.session.audioVideo.chooseVideoInputDevice(deviceId);
      }
      if (this.deviceController) {
        await this.deviceController.chooseVideoInputDevice(deviceId);
        await this.deviceController.startVideoPreviewForVideoInput(this.videoPreviewTarget);

      }
    },

    // Toggle through the available cameras, save selection in local storage
    async changeCamera(target) {
      this.selectedVideoInputDevice = (this.selectedVideoInputDevice + 1) % this.videoInputDevices.length;
      this.deviceController
          .chooseVideoInputDevice(this.videoInputDevices[this.selectedVideoInputDevice].deviceId)
          .then(() => {
            this.deviceController.startVideoPreviewForVideoInput(
              this.target
            );
            localStorage.setItem("preferredVideoInputDevice", this.selectedVideoInputDevice);
          })
          .catch((error) => {
            console.error(error);
            document.dispatchEvent(new CustomEvent("Nomiri:PermissionDenied"));
          });
    },

    async audioInChanged(event) {
      const deviceId = $(event.currentTarget).val();

      console.log("useAws::audioInChanged", deviceId);
      this.session.audioVideo
        .chooseAudioInputDevice(deviceId)
        .then((result) => {
          console.log("useAws::audioInChanged", result);
          // this.analyzer(this.session1);
        });
    },

    async toggleShareScreen() {
      if (this.sharing) {
        this.sharing = false;
        this.session.audioVideo.stopContentShare();
      } else {
        this.sharing = true;
        await this.session.audioVideo.startContentShareFromScreenCapture();
      }
    },

    async awsStartAnalyzer(callback) {
      this.analyserNode = this.deviceController.createAnalyserNodeForAudioInput();
      if (!this.analyserNode) {
        console.error(
          "useAws::awsStartAnalyzer() failed to create analyzer node"
        );
        return;
      }

      const data = new Uint8Array(this.analyserNode.fftSize);
      const canvasContext = this.audioMeterTarget.getContext("2d");

      let frameIndex = 0;
      this.analyserNodeCallback = () => {
        if (frameIndex === 0 && this.analyserNode != null) {
          this.analyserNode.getByteTimeDomainData(data);
          const lowest = 0.01;
          let max = lowest;
          for (const f of data) {
            max = Math.max(max, (f - 128) / 128);
          }
          let normalized =
            (Math.log(lowest) - Math.log(max)) / Math.log(lowest);
          let percent = Math.min(Math.max(normalized * 100, 0), 100) / 100;

          if (callback != null) {
            callback(percent);
          }

          canvasContext.clearRect(
            0,
            0,
            canvasContext.canvas.width,
            canvasContext.canvas.height
          );
          canvasContext.fillStyle = percent > 0.7 ? "#00ff00" : "#BadA55";
          canvasContext.fillRect(
            0,
            0,
            percent * canvasContext.canvas.width,
            canvasContext.canvas.height
          );
          canvasContext.fillStyle = "#262626";
          canvasContext.font = "48px impact";
        }
        frameIndex = (frameIndex + 1) % 2;

        if (this.analyserNode != null) {
          requestAnimationFrame(this.analyserNodeCallback);
        }
      };
      requestAnimationFrame(this.analyserNodeCallback);
    },

    async awsStopAnalyzer() {
      if (this.analyserNode) {
        this.analyserNode.removeOriginalInputs();
        this.analyserNode = null;
      }
    },
  });
};
