import { Controller } from "stimulus";
/**
 * PopupController for the digivent.
 *
 * - Listen on the popup frame and disable the background
 * - Click on background closes it
 * - Click on close button to close it
 */
export default class extends Controller {
  connect() {
    console.log("PopupController::connect()");

    this.observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        console.log("MUTATION", mutation)
        if (mutation.type === "attributes" && mutation.attributeName === "src") {
          // src attribute changed
          this.open();
        }
      })
    })

    this.observer.observe(this.element, { attributes: true })

    $(this.element).on('click', (event) => {
      console.log(event);
      if (event.originalEvent.target == $(this.element).find(".modal")[0]) {
        this.close();
      }
    })
  }

  open() {
    $(".wrapper").addClass("disabled");
  }

  close() {
    $(this.element).empty();
    $(".wrapper").removeClass("disabled");
  }
}
