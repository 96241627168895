import { Controller } from "stimulus";
import consumer from '../../../channels/consumer';


const Favico = require("favico.js");

/**
 * MainController for the digivent.
 *
 * One websocket connection, then subscription to multiple streams.
 * 
 * - Tooltip handling
 * - Notifications
 */
export default class extends Controller {
  connect() {
    console.log("MainController::connect id", this.identifier, document.documentElement.hasAttribute("data-turbolinks-preview"));
    this.element[this.identifier] = this;

    const profileId = this.data.get("profile")
    if (profileId) {
      this.connectWebsocket(profileId);
      this.notifications = [];
      this.toast = $(".toast");

      this.toast.on("click", () => {
        this.hideToast();
      });
    }

    $("a").on("click", (event) => {
      const trigger = event.currentTarget;

      $(".menu-entry").removeClass("current");

      if ($(trigger).hasClass("menu-entry")) {
        $(trigger).addClass("current");
      }
    });

    document.addEventListener("turbo:submit-start", function () {
      if (this.spinner != null) {
        clearTimeout(this.spinner);
      }

      this.spinner = setTimeout(() => {
        $("#loader-frame").addClass("visible");
        this.spinner = null;
      }, 500);
    });

    document.addEventListener("turbo:before-render", function () {
      if (this.spinner != null) {
        clearTimeout(this.spinner);
      }

      $("#loader-frame").removeClass("visible");
    });

    this.favicon = new Favico({
      animation: "none",
      bgColor: "#000000",
      textColor: "#ffffff",
    });
  }

  disconnect() {
    console.log("MainController::disconnect");
  }

  connectWebsocket(connect_as) {
    console.log("MainController::connectWebsocket connect as", connect_as);
    this.subscribeCommunicationChannel(connect_as);
    this.subscribeGlobalChannel(connect_as);
  }

  subscribeCommunicationChannel(connect_as) {
    this.chatChannel = consumer.subscriptions.create(
      { channel: "CommunicationChannel", connect_as: connect_as },
      {
        received: (data) => {
          console.log("CommuncationChannel received=", data);
          if (data.communication) {
            this.notificationReceived(data.communication);
          }
          if (data.sticky) {
            this.showNotificationSticky(data.sticky);
          }
          if (data.remove_sticky) {
            this.removeSticky(data.remove_sticky);
          }
          if (data.status) {
            this.updateStatus(data.status);
          }
        },
      }
    );
  }

  subscribeGlobalChannel(connect_as) {
    this.globalChannel = consumer.subscriptions.create(
      { channel: "GlobalChannel", connect_as: connect_as },
      {
        received: (data) => {
          if (data.queue_status) {
            this.onQueueStatus(data.queue_status);
          }
        },
      }
    );
  }

  onQueueStatus(data) {
    document.dispatchEvent(
      new CustomEvent("Nomiri:QueueStatusUpdate", { detail: data })
    );
  }

  notificationReceived(communication) {
    this.notifications.push(communication);
    this.checkDisplayToast();
  }

  checkDisplayToast() {
    if (this.toast.hasClass("active")) {
      setTimeout(() => {
        this.checkDisplayToast();
      }, 1000);
    } else {
      if (this.notifications.length == 0) {
        return;
      }
      const communication = this.notifications.shift();
      this.showNotificationToast(communication);
    }
  }

  /**
   * Shows the communication in a Toast like message on screen. If the communcation hasn't the
   * important flag set, the message will dissapear after 3 seconds.
   * @param {*} communication
   */
  showNotificationToast(communication) {
    this.toast.find(".title").text(communication.title);
    this.toast.find(".text").text(communication.text);

    // Hide the button if there is no text

    this.toast
      .find(".link")
      .attr("href", communication.link)
      .text(communication.link_text);

    this.toast.addClass("active");

    if (communication.flags != 1) {
      setTimeout(() => {
        this.hideToast();
      }, 5000);
    }
  }
  
  showNotificationSticky(html) {
    $("body").append(html)
  }

  hideToast() {
    this.toast.removeClass("active");
  }

  removeSticky() {
    $("#sticky-notification").remove()
  }

  updateStatus(status) {
    $("#unread_chats_badge").removeClass("active");
    $("#unread_messages_badge").removeClass("active");
    $("#unread_notifications_badge").removeClass("active");

    this.favicon.badge(status.unread_notifications);

    if (status.unread_chats > 0) $("#unread_chats_badge").addClass("active");
    if (status.unread_messages > 0)
      $("#unread_messages_badge").addClass("active");
    if (status.unread_notifications > 0)
      $("#unread_notifications_badge").addClass("active");
  }

  closeTip(event) {
    $(event.currentTarget).hide();
  }
}
