/**
 * Controlling the chat, and the attendeelist!
 *
 * @param {} controller
 * @param {*} consumer
 * @param {*} options
 */
export const useChat = (controller, consumer, options) => {
  const scrollDown = () => {
    controller.options.targetChat.scrollTop =
      controller.options.targetChat.scrollHeight;
  };

  const appendLine = (data) => {
    console.log("useChat::appendLine()", data);
    controller.options.targetChat.insertAdjacentHTML("beforeend", data.html);
    scrollDown();
  };

  const update_participant = (html) => {
    const id = html.id;
    $("#p_" + id).replaceWith(html);
  };

  const new_participant = (html) => {
    console.log("new p: ", html);
    var content = $(html)
    if (content[0].nodeType == 8) {
      content = content.next();
    }
    const id = content.attr("id");
    const name = content.find(".name").text();

    if (!controller.options.admin) {
      $(html).find(".action-bar").remove();
    }

    $(".particpant-list").append(content);
    document.dispatchEvent(
      new CustomEvent("Nomiri:NewParticpant", {detail: { id: id, name: name },})
    );
  };

  const remove_participant = (id) => {
    console.log("remove: ", "#p_" + id);
    document.dispatchEvent(
      new CustomEvent("Nomiri:RemoveParticipant", { detail: id })
      );
    $("#p_" + id).remove();

    // User got removed, close
    if (id == controller.options.participantId) {
      console.log("Seding close");
      document.dispatchEvent(new CustomEvent("Nomiri:CloseConference"));
    }
  };

  const connect = (consumer, token) => {
    console.log("useChat::connect()", consumer, token);
    controller.chatChannel = consumer.subscriptions.create(
      {
        channel: "ConferenceChannel",
        token: token,
        connect_as: controller.options.connect_as,
      },
      {
        received: (data) => {
          console.log("useChat() received=", data);

          if (data.command == "update_participant")
            update_participant($.parseHTML(data.html)[0]);
          if (data.command == "new_participant")
            new_participant($.parseHTML(data.html)[0]);
          if (data.command == "remove_participant")
            remove_participant(data.html);
          if (data.command == "new_message") appendLine(data);
          if (data.command == "refresh") {
            document.dispatchEvent(new CustomEvent("Nomiri:RefreshConference"));
          }
          if (data.command == "queue_status") {
            document.dispatchEvent(new CustomEvent("Nomiri:UpdateQueue",  { detail: data }));
          }
          // appendLine(data);
        },
      }
    );

    $(controller.chatSendTarget).on("click", () => {
      controller.sendMessage();
    });
  };

  const disconnect = () => {};

  const sendMessage = () => {
    console.log("UseChat::sendMessage", controller.options.input.value);
    controller.chatChannel.send({
      message: controller.options.input.value,
    });
    controller.options.input.value = "";
  };

  const keyUp = (event) => {
    if (event.keyCode == 13) {
      sendMessage();
    }
  };

  controller.options = options;
  console.log("UseChat init", controller.options);

  if (controller.options.input) {
    controller.options.input.addEventListener(
      "keyup",
      (event) => keyUp(event),
      false
    );
  }

  if (controller.options.targetChat) {
    setTimeout(() => {
      scrollDown();
    }, 50);
  }
  controller.sendMessage = sendMessage;
  connect(consumer, controller.options.token);
};
