import { ConsoleLogger } from "amazon-chime-sdk-js";
import { Controller } from "stimulus";
import { useAwsChime } from "../../mixins/use_aws_chime";

export default class extends Controller {
  static targets = ["video", "audioMeter"];

  connect() {
    console.log("VideoSetupController::connect");

    this.activeStep = 0;
    useAwsChime(this);

    document.addEventListener("Nomiri:PermissionDenied", (e) => {
      console.log("VideoSetupController::PermissionDenied()");  

      $(this.element).find("#permission-error").css("display","flex");
      $(this.element).find("video").css("display","none");

      this.setStepStatus(2, -1);
    });
  }

  disconnect() {
    console.log("VideoSetupController::disconnect()");

    this.awsStopPreview();
    this.awsStopAnalyzer();
  }

  startTest() {
    this.activateStep(1);
  }

  // Audio test, user confirms he can hear the sound
  step1Yes() {
    this.setStepStatus(1);
    this.activateStep(2);
  }

  // Audio test, user can't hear the sound
  step1No() {
    this.setStepStatus(1, -1);
    $(this.element).find(".step1").addClass("has-errors");
  }

  step2Yes() {
    this.setStepStatus(2);
    this.activateStep(3);
  }

  step2No() {
    this.setStepStatus(2, -1);
    $(this.element).find(".step2").addClass("has-errors");
  }

  step3Yes() {
    this.setStepStatus(3);
    this.activateStep(4);
  }

  step3No() {
    this.setStepStatus(3, -1);
    $(this.element).find(".step3").addClass("has-errors");
  }

  step4Yes() {
    this.activateStep(4);
  }

  playSound() {
    $(this.element).find("audio")[0].play();
  }

  setStepStatus(step, status = 1) {
    let elm =  $(this.element).find(`#step-header-${step}`);
    elm.removeClass("success, error");
    elm.addClass(status ==  1 ? "success" : "error");
  }

  activateStep(step) {
    console.log("VideoSetupController::activateStep", step);

    this.activeStep = step;
    $(this.element).find(".step").addClass("hidden");
    $(this.element).find(".header-step").removeClass("text-blue-500");

    let elm = $(this.element).find(".step" + step);
    elm.removeClass("has-errors");
    elm.removeClass("hidden");

    $(this.element).find(`#step-header-${step}`).addClass("text-blue-500");

    switch (step) {
      case 2:
        this.activateStep2();
        break;
      case 3:
        this.activateStep3();
        break;
      case 4:
        this.activateStep4();
        break;
    }
  }

  /** Ask for permission of camera and mic and show the camera so the user can confirm he can see himself
   */
  activateStep2() {
    this.awsInit();
    this.awsActivatePreview(this.videoTarget);
  }

  activateStep3() {
    this.awsStartAnalyzer( (percent) => {
        if (percent > 0.7) {
            this.setStepStatus(3);
            this.activateStep(4)
        }
    });
  }

  activateStep4() {
    this.awsStopPreview();
    this.awsStopAnalyzer();
  }
}
