import { Controller } from "stimulus";
import Croppie from "croppie";

export default class extends Controller {
  connect() {
    console.log("Croppie::connect()", this.data.get("url"));

    this.cropType = "circle"
    if (this.data.get("crop-type")) {
      this.cropType = this.data.get("crop-type")
    }

    this.uploadCrop = $(this.element)
      .find(".cropper")
      .croppie({
        enableExif: true,
        viewport: {
          width: this.data.get("width"),
          height: this.data.get("height"),
          type: this.cropType,
        },
      });

    if (this.data.get("url")) {
      $(this.uploadCrop).croppie("bind", { url: this.data.get("url") });
    }

    this.setup();

    this.modal = $(this.element).find(".hs-overlay")[0];
    this.preview = $(this.element).find(".preview-image");
    this.dataField = $(this.element).find(".data-field");

    this.preview.on("click", () => {
      this.input.trigger("click");
    });
  }

  setup() {
    this.input = $(this.element).find("input.file-chooser");

    this.input.on("change", () => {
      this.readFile(this.input[0]);
    });
  }

  // Reads the select file from the input and opens it in a popup
  // with croppie
  readFile(input) {
    console.log("this.popup", this.modal[0]);

    HSOverlay.open(this.modal)

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        $(".upload-demo").addClass("ready");
        console.log(this.element);

        $(this.uploadCrop)
          .croppie("bind", { url: e.target.result })
          .then(function () {
            console.log("jQuery bind complete");
          });
      };

      reader.readAsDataURL(input.files[0]);

    } else {
      alert("Sorry - you're browser doesn't support the FileReader API");
    }
  }

  // Assign the cropped image to the hidden input file
  assignImage(event) {
    event.preventDefault();

    $(this.uploadCrop)
      .croppie("result", {
        type: "canvas",
        size: "original",
      })
      .then((resp) => {
        console.log("assign image");

        this.preview.attr("src", resp);
        this.dataField.val(resp);

        this.close()
      });
  }

  close() {
    HSOverlay.close(this.modal)
  }
}
