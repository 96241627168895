import { Controller } from "stimulus";

/**
 * Controller for the contry / region select boxes
 */
export default class extends Controller {
  connect() {
    this.select = $(this.element).find("input");
    console.log("ZipcodeController::connect()", this.select.attr("id"));

    this.select.on("change", () => {
      this.onChange();
    });
  }

  onChange() {
    console.log("ZipcodeController::onChange()");

    const code = this.select.val();

    $.ajax({
      url: "/domain_data/zip_code_info",
      data: { code: code },
      type: "GET",

      success: (data) => {
        console.log("ZipcodeController::onChange()", data);
        if (data.length === 0) {
          console.error("ZipcodeController::onChange() zip not found");
          this.select.addClass("is-invalid");
          $(this.element).find(".error-message").removeClass("hidden");
        } else {
          $("#entity_city").val(data[0].city);
          this.select.removeClass("is-invalid");
          $(this.element).find(".error-message").addClass("hidden");
        }
      },
    });
  }
}
