import { Controller } from "stimulus";
import { createConsumer } from "@rails/actioncable";
import { useAwsChime } from "../../mixins/use_aws_chime";

/**  Controller for the user lobby
 * - Preview of camera, to test it etc
 * - Leave
 **/


export default class extends Controller {

  static targets = ["videoPreview", "video", "modeSetup", "modeWaiting", "modeInterview", "cv", "videoSelect",
                    "audioInSelect", "audioOutSelect", "audio"];

  connect() {
    console.log("Lobby controller connected");

    useAwsChime(this);
    this.awsInit();
    this.awsActivatePreview(this.videoPreviewTarget);
    HSOverlay.open(document.getElementById('video-setup'));
  }
}
