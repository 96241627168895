import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails"
/**
 * NavigationController for the digivent.
 *
 * Push any state changes in the connected turbo frame to the history
 */
export default class extends Controller {

  connect() {
    this.element[this.identifier] = this;
    console.log("MainController::connect()");

    this.connectNavigation();
  }

  connectNavigation() {
    this.observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "src") {
          history.pushState({ turbo_frame_history: true }, "", this.element.getAttribute("src"))
        }
      })
    })

    this.observer.observe(this.element, { attributes: true })

    this.popStateListener = ((event) => {
      if (event.state.turbo_frame_history) {
        Turbo.visit(window.location.href, { action: 'replace' })
      }
    }) 

    window.addEventListener("popstate", this.popStateListener)
  }
}
