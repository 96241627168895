import { Controller } from "stimulus";
import { debounce } from "rxjs/operators";
import { interval, Subject } from "rxjs";

/**
 * Controller for the entities lists, use of filter and search
 */
export default class extends Controller {
  static targets = ["search"];

  connect() {
    this.search_url = this.data.get("search-url");
    this.element[this.identifier] = this;

    // Click on detail link open popup, load data using turbo
    this.input = new Subject();
    this.input
      .pipe(debounce(() => interval(300)))
      .subscribe(() => this.search());

    this.initLoadMore();
  }

	disconnect() {
    console.log("EntitiesController::disconnect()");
    clearInterval(this.refresher)
	}

  initLoadMore() {
    if (!$(".load-more")[0]) {
      return null;
    }

    console.log("init loadmore");
    $("#local-frame").off("scroll").on("scroll", () => {
      this.checkLoadMoreVisible();
    });

    this.refresher = setInterval( () => {
	  this.checkLoadMoreVisible();
    }, 1000 );
  }

  checkLoadMoreVisible() {
    if (this.loading) {
      return;
    }

    var element = $(".load-more")[0];
    if (this.checkVisible(element)) {
      this.loadMore(element);
    }
  }

  checkVisible(elm) {
    if (elm == null) {
      return;
    }
    console.log("checkVisible")
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }
  // Called from de input debounce after a idle of 300ms, execute the search request
  search() {
    // Get the filter information
    var url = this.search_url;
    url += "?q=" + this.searchTarget.value;
    $("#result-frame").attr("src", url);
  }

  openFilter() {
    $(this.element).find(".modal").toggleClass("shown");
  }
  // Called from the search input every time its changed
  inputChanged() {
    this.input.next();
  }

  loadMore(element) {
    this.loading = true;
    var url = $(element).find("a").attr("href")
    console.log("loadMore", url);
    $.get( url, (data) => {
      var content = $(data).find(".entity-list").children();
      console.log(content);

      $(".replace").remove();
      content.appendTo("#entities");
      this.loading = false;

      document.dispatchEvent(new CustomEvent("loadmore"));
    });
  }
}
