import { Controller } from "stimulus";

/**
 * Controller for the contry / region select boxes
 */
export default class extends Controller {
  connect() {
    this.select = $(this.element).find("select");
    console.log("CitySearchController::connect()", this.select);

    this.select.select2({
      ajax: {
        url: "/domain_data/city_search",
        dataType: 'json'
      }
    });

    $(document).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });
  }
}
