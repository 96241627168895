import { Controller } from "stimulus";
/**
 * Controller for a simple modal
 *
 * - Click on background closes it
 * - Click on close button to close it
 */
export default class extends Controller {
  connect() {
    console.log("ModalController::connect()");
  }

  close() {
    $(this.element).removeClass("shown");

    document.dispatchEvent(new CustomEvent("Nomiri:CloseModal"));
  }
}
