import { Controller } from "stimulus";
import { useChat } from "../../mixins/use_chat";

/**
 * Controller for the coffee room.
 */
export default class extends Controller {
  connect() {
    console.log("CoffeeController::connect()");
    this.element[this.identifier] = this;

    const mainElement = $(".global-space")[0];
    const mainController = this.application.getControllerForElementAndIdentifier(
      mainElement,
      "main"
    );

    console.log("CoffeeController::connect() mainController", mainElement);
    console.log(
      "CoffeeController::connect() mainController",
      mainController.consumer
    );

    const options = {
      targetChat: ".chat",
      targetAttendees: null,
      input: ".chat-input input",
      token: this.data.get('token'),
    };

    useChat(this, mainController.consumer, options);
  }
}
