import { Controller } from "stimulus";
import { debounce } from "rxjs/operators";
import { interval, Subject } from "rxjs";

/**
 * Showing users online, and let search in them
 */
export default class extends Controller {
  static targets = ["search"];

  connect() {
    this.search_url = this.data.get("search-url");

    console.log("OnlineController::connect()", this.search_url);
    console.log("OnlineController::connect()", this.searchTarget);

    // Click on detail link open popup, load data using turbo
    this.input = new Subject();
    this.input
      .pipe(debounce(() => interval(300)))
      .subscribe(() => this.search());

    document.addEventListener("Nomiri:RefreshUsersOnline", (e) => {
      console.log("Nomiri:RefreshUsersOnline");
      this.search();
    });

  }

  // Called from de input debounce after a idle of 300ms, execute the search request
  search() {
    // Get the filter information
    var url = this.search_url;
    url += "&q=" + this.searchTarget.value;
    $("#result-frame").attr("src", url);
  }

  // Called from the search input every time its changed
  inputChanged() {
    console.log("*");
    this.input.next();
  }

  // User has been select, send customevent
  select(event) {
    const id =  $(event.currentTarget).data("id");
    document.dispatchEvent(new CustomEvent("Nomiri:UserSelect", { detail: id }));

    //TODO, no responable for this
    $("#modal-invite").removeClass("shown");
  }

  openDetailsModal(event) {
    const modalID = event.target.getAttribute("data-modal");

      if (modalID) {
        document.getElementById(modalID).classList.add("shown");
      }
  }
}
