export const useSlotControl = (controller) => {

  Object.assign(controller, {

    initSlotControl(baseUrl) {
      this.baseUrl = baseUrl
    },

    slotControlStart() {
      console.log('useSlotControl::slotControllStart()')
      this.slotControlSendCommand('start');
    },

    slotControlStop() {
      console.log('useSlotControl::slotControllStop()')
      this.slotControlSendCommand('stop');
    },

    slotControlConfirm() {
      console.log('useSlotControl::slotControllConfirm()')
      this.slotControlSendCommand('confirm');
    },

    slotControlFinish() {
      console.log('useSlotControl::slotControllFinish()')
      this.slotControlSendCommand('finish');
    },

    slotControlSendCommand(command) {
      $.post(`${this.baseUrl}?c=${command}`, { _method: 'patch'}, (data) => {
        console.log("useSlotControl::SlotControlSendCommand()", data);
      });

    },
  }
)};
