// Service worker
// Register the serviceWorker script at /serviceworker.js from your server if supported
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js')
  .then(function(reg) {
     console.log('Service worker change, registered the service worker');
  });
}
// Otherwise, no push notifications :(
else {
  console.error('Service worker is not supported in this browser');
}

// gallery viewer
let viewerVisor;
let viewerVisorImage;

/*
  Smooth scrolling to an anchor.

  params:
    object: e           => event
    object: [respond]   => <a> object (link)
*/
function scrollAnchors(e, respond = null) {
	const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

  e.preventDefault();

  const targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');

  if (targetID != undefined && targetID != "#") {
    const targetAnchor = document.querySelector(targetID);
    if (!targetAnchor) return;
    const originalTop = distanceToTop(targetAnchor);

    window.scrollBy({top: originalTop, left: 0, behavior: 'smooth'});

    const checkIfDone = setInterval(function() {
      const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
      if (distanceToTop(targetAnchor) === 0 || atBottom) {
        targetAnchor.tabIndex = '-1';
        targetAnchor.focus();
        window.history.pushState('', '', targetID);
        clearInterval(checkIfDone);
      }
    }, 100);
  }
}

window.isSmallDevice = function() {
  let vertical = true;

  if (window.screen.orientation != undefined) {
    vertical = ["portrait-primary", "portrait-secondary"].includes(window.screen.orientation.type); }

  return Math.max(document.documentElement.clientWidth, window.clientWidth || 0) <= 1024 && vertical;
}

window.isInViewport = function(elem) {
  const width = elem.offsetWidth;
  const height = elem.offsetHeight;
  let top = elem.offsetTop;
  let left = elem.offsetLeft;

  while (elem.offsetParent) {
    elem = elem.offsetParent;
    top += elem.offsetTop;
    left += elem.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
};

window.throttle = (func, limit) => {
  let lastFunc;
  let lastRan;

  return function() {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  }
}

window.debounce = (func, delay) => {
  let inDebounce;

  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  }
}

window.getSiblings = function(elem) {
  const siblings = [];
  let sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
			siblings.push(sibling); }

    sibling = sibling.nextSibling;
  }

  return siblings;
};

window.getDisplayWidth = function() {
  return Math.max(document.documentElement.clientWidth, window.clientWidth || 0); }

window.macy = function(args) {
  Macy(args); }

/*
  Sets a timeout for a devise notification to close it automatically.

  params:
    timeout (integer) -> timeout in ms (default 5s).
    [id] (string)     -> to avoid erase all the message presented instead of a specific one
*/
function flashMessageTimeout(timeout = 5000, id = undefined) {
  window.clearTimeout();

  window.setTimeout(event => {
    const notification = id != undefined ? document.querySelector("#" + id) : document.querySelector("#alert_box");

    if (notification != undefined) {
      notification.remove(); }
  }, timeout);
}

/*
  Search the next/prev gallery viewer image.

  params:
    integer : dir => expect -1 for prev item or 1 for the next one
*/
function getViewerImage(dir, perform = true) {
  const parent = viewerVisorImage.getAttribute("data-parent");
  const n = viewerVisorImage.getAttribute("data-n");
  const prox = parseInt(n) + dir;
  const proxImage = document.querySelector(`#${parent}_${prox}`);

  if (proxImage != undefined && perform) {
    viewerVisorImage.setAttribute("src", proxImage.getAttribute("data-src"));
    viewerVisorImage.setAttribute("data-parent", proxImage.getAttribute("data-parent"));
    viewerVisorImage.setAttribute("data-n", proxImage.getAttribute("data-n"));
  }

  return proxImage != undefined;
}

/*
  Ennable/disable prev and next buttons depending on current photo.

  params:
    integer : dir => expect -1 for prev item or 1 for the next one
    object : container => prev and next buttons container
*/
function checkEnabledButtons(dir, container, perform = true) {
  if (getViewerImage(dir, false)) {
    if (perform) {
      container.querySelector(".swiper-button-prev").classList.remove("swiper-button-disabled");
      container.querySelector(".swiper-button-next").classList.remove("swiper-button-disabled");
    } else {
      if (dir < 0) {
        container.querySelector(".swiper-button-prev").classList.remove("swiper-button-disabled");
      } else {
        container.querySelector(".swiper-button-next").classList.remove("swiper-button-disabled");
      }
    }
  } else {
    if (dir < 0) {
      container.querySelector(".swiper-button-prev").classList.add("swiper-button-disabled");
    } else {
      container.querySelector(".swiper-button-next").classList.add("swiper-button-disabled");
    }
  }
}

function setupLoadMoreForList(list) {
  const xhr = new XMLHttpRequest();
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const loadMoreButton = entry.target;
        observer.unobserve(loadMoreButton);
        loadMoreButton.click();
      }
    });
  });

  xhr.onreadystatechange = function() {
    const entitiesList = list.querySelector(".items");
    if (!entitiesList) {
      console.error("No .items found in list");
      return;
    }

    if(xhr.readyState === 4) {
      if(xhr.status === 200) {
        const node = document.createElement("div");
        node.innerHTML = xhr.responseText;

        const dummies = entitiesList.querySelectorAll(".dummy");
        for (let dummy of dummies) {
          dummy.remove(); 
        }

        const loadMoreEntities = list.querySelector(".load-more-entities");
        if (loadMoreEntities != undefined) {
          const newLoadMoreEntities = node.querySelector(".load-more-entities");
          if (newLoadMoreEntities != undefined) {
            loadMoreEntities.parentNode.insertBefore(newLoadMoreEntities, loadMoreEntities); 
          }

          loadMoreEntities.remove();
        }
        if (node.querySelector(".items") == undefined) {
          console.log('no more results');
        } else {
          entitiesList.innerHTML = entitiesList.innerHTML + node.querySelector(".items").innerHTML;
        }
      } else {
        const node = document.createElement("div");
        node.innerHtml = "<p>Hi ha hagut un problema en tractar de carregar més elements.</p><p>Ha habido un problema al tratar de cargar más elementos.</p><p>There's been a problem trying to load more items.</p>";
        entitiesList.appendChild(node);
      }
      const loadMoreButtons = document.querySelectorAll('.load-more-entities');
      loadMoreButtons.forEach(loadMoreButton => {
        observer.observe(loadMoreButton);
      });
    }
  }

  list.addEventListener("click", function() {
    xhr.open("get", event.target.getAttribute("data-url"));
    xhr.send();
  });

  const loadMoreButtons = document.querySelectorAll('.load-more-entities');
  loadMoreButtons.forEach(loadMoreButton => {
    observer.observe(loadMoreButton);
  });
}

$(document).on('turbo:load', function() {
  $(".auto-submit").change(function(){
    $(this).closest("form").submit();
  });

  // bug fix: Firefox back-button issue <>
  window.addEventListener("unload", event => {
    window.removeEventListener("unload", this); });
  // bug fix: Firefox back-button issue </>

  // smooth anchor scrolling <>
  let links = document.getElementsByTagName("a");
  for (var i = 0; i < links.length; i++) {
    const link = links[i];

    if ((link.href && link.href.indexOf("#") != -1) && ((link.pathname == location.pathname) || ('/' + link.pathname == location.pathname)) && (link.search == location.search)) {
      link.onclick = scrollAnchors; }
  }
  // smooth anchor scrolling </>

  // load more entities <>
  const lists = document.querySelectorAll(".long-list");
  for (let list of lists) {
    setupLoadMoreForList(list)
  }
  // load more entities </>

  // gallery viewer <>
  // const screenWidth = window.innerWidth || documentd.documentElemente.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;

  if (screenWidth >= 768) {
    viewerVisor = document.getElementById("viewer_visor");

    if (viewerVisor) {
      if (document.querySelector('.related-to-gallery-viewer-visor') != undefined) {
        viewerVisor.style.display = "flex";
        viewerVisorImage = viewerVisor.querySelector(".gallery-viewer-visor--image");

        const buttonsContainer = viewerVisor.querySelector(".swiper-buttons");

        viewerVisor.addEventListener("click", function() {
          if (event.target.classList.contains("swiper-button-prev")) {
            getViewerImage(-1);
            checkEnabledButtons(-1, event.target.parentNode);
          }

          else if (event.target.classList.contains("swiper-button-next")) {
            getViewerImage(1);
            checkEnabledButtons(1, event.target.parentNode);
          }

          else if (!viewerVisorImage.contains(event.target)) {
            this.classList.remove("active"); }
        });

        document.addEventListener("click", function() {
          if (event.target.classList.contains("gallery-viewer-item")) {
            viewerVisor.classList.add("active");
            const img = event.target.getAttribute("data-src");
            viewerVisorImage.setAttribute("src", img);
            viewerVisorImage.setAttribute("data-parent", event.target.getAttribute("data-parent"));
            viewerVisorImage.setAttribute("data-n", event.target.getAttribute("data-n"));

            checkEnabledButtons(-1, buttonsContainer, false);
            checkEnabledButtons(1, buttonsContainer, false);
          }
        });
      } else {
        viewerVisor.style.display = "none";
      }
    }
  }
  // gallery viewer </>

  // alert box <>
  if (document.getElementById("alert_box_close") != undefined) {
    document.addEventListener("click", function() {
      if (event.target.id === "alert_box_close") {
        flashMessageTimeout(0); }
    });

    flashMessageTimeout();
  }
  // alert box </>

  // Html text editor
  $.trumbowyg.svgPath = '/icons.svg';
  $(".html-editor").trumbowyg({
    btns: [
      ["strong", "em"],
      ["unorderedList", "orderedList"],
      ["formatting"],
      ["link"],
    ],
    removeformatPasted: true,
    defaultLinkTarget: "_blank",
  });

});

$(document).on('turbo:click', function() {
  var scrollPosition = $("#content").scrollTop();
  localStorage.setItem("scrollPosition" + window.location.pathname, scrollPosition);
});

$(document).on('turbo:load', function() {
  var scrollPosition = localStorage.getItem("scrollPosition" + window.location.pathname);
  if(scrollPosition) {
    $("#content").scrollTop(scrollPosition);
  }
  localStorage.removeItem("scrollPosition" + window.location.pathname);
});


window.setupLoadMoreForList = setupLoadMoreForList
