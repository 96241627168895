import { Controller } from "stimulus";
import { DateTime } from "luxon";

import { useChat } from "../../mixins/use_chat";
import { useTileLayout } from "../../mixins/use_tile_layout";
import { useAwsChime } from "../../mixins/use_aws_chime";
import { useSlotControl } from "../../mixins/use_slot_control";
import consumer from '../../../channels/consumer';

/**
 * QueueSlotController
 *
 * - Connects to the global channel for queue updates
 * - Updating the timer
 * - UseAws if online event
 */
export default class extends Controller {

  static targets = [
    "audio",
    "audioInSelect",
    "audioOutSelect",
    "cv",
    "modeInterview",
    "modeSetup", "modeWaiting",
    "video",
    "videoPreview",
    "videoSelect",
  ];

  connect() {
    this.userQueueId = this.data.get("user-queue-id");
    this.online = this.data.get("online");
    this.controls = this.data.get("controls");
    this.baseUrl = this.data.get("base-url");
    this.queueStatus = this.data.get("queue-status");

    this.setupTime();
    if (!this.userQueueId) {
      return;
    }

    this.setupTimer();
    this.setupConnection();
    this.setupSlotControl();

    if (this.online) {
      this.setupVideoConference();
    }
  }

  setupVideoConference() {
    console.log("QueueSlotController::connect() Connecting AWS");

    this.showContent('video');
    document.addEventListener("Nomiri:PermissionDenied", (e) => {
      console.log("ConferenceController::PermissionDenied()");
      this.onNoVideo();
    });
    document.addEventListener("Nomiri:MeetingEnded", (e) => {
      console.log("ConferenceController::MeetingEnded()");
      window.location.search += '&raws=1';
    });

    document.addEventListener("Nomiri:NewParticpant", (e) => {
      console.log("ConferenceController::connect() Nomiri:NewParticpant", e.detail);
      this.createTile(e.detail.id);
      ctrl.tileLayoutInit($(".video-grid"));
      //this.refreshQueue();
    });

    var ctrl = this;
    document.addEventListener("Nomiri:RemoveParticipant", (e) => {
      console.log("ConferenceController::connect() Nomiri:RemoveParticipant", e.detail);
      $(`#p_${e.detail}`).closest(".video-tile").remove();
      ctrl.tileLayoutInit($(".video-grid"));
      //ctrl.refreshQueue();
    });

    useAwsChime(this);
    useTileLayout(this);

    const options = {
      token: this.data.get("token"),
      connect_as: this.data.get("connect-as"),
      participantId: this.participantId,
    };

    useChat(this, consumer, options);
    this.connectAwsMeeting();

    setTimeout(() => {
      this.tileLayoutInit($(".video-grid"));
    }, 100);

    this.video = true;
  }

  onNoVideo() {
    console.log('QueueSlotController::onNoVideo');
    HSOverlay.open(document.getElementById('no-audio-video'));
  }

  setupVideoConferencePreview() {
    console.log('QueueSlotController::connectOnline')
    useAwsChime(this);

    this.awsInit();
    this.awsActivatePreview(this.videoPreviewTarget);
    HSOverlay.open(document.getElementById('video-setup'));
  }

  setupTime() {
    document.querySelectorAll(".time").forEach((element) => {
      setInterval(() => {
        element.textContent = DateTime.local().toLocaleString(
          DateTime.TIME_24_WITH_SECONDS
        );
      }, 1000);
    });
  }

  setupTimer() {
    document.querySelectorAll(".time-delta").forEach((element) => {
      var start = $(element).data("time");
      setInterval(() => {
        var s = DateTime.fromMillis(start);
        element.textContent = s.diffNow().negate().toFormat("mm:ss");
      }, 1000);
    });
  }

  setupConnection() {
    this.connectWebsocket();
  }

  setupSlotControl() {
    useSlotControl(this);
  }

  disconnect() {
    if (this.video) {
      this.disconnectConference(this.session);
    }
  }

  onClickCVS() {
    console.log("onClickCVS");
    this.showContent("cv");
  }

  onClickVideo() {
    console.log("onClickVideo");
    this.showContent("video");
  }

  onClickHelp() {
    console.log("onClickHelp");
    this.showContent("help");
  }

  onClickAgenda() {
    console.log("onClickAgenda");
    this.showContent("agenda");
  }

  showContent(sectionName) {
    $(this.element).removeClass("active-cv active-help active-agenda active-video").addClass(`active-${sectionName}`);

    $(".main-section").removeClass("active");
    $(`.section-${sectionName}`).addClass("active");
    $(".queue-info-tabs .tab-ref").removeClass("active");
    $(`.queue-info-tabs .tab-ref-${sectionName}`).addClass("active");
  }

  connectWebsocket() {
    console.log(
      "QueueSlotController::connectWebsocket connect as",
      this.userQueueId
    );
    this.subscribeUserQueueChannel();
  }

  subscribeUserQueueChannel() {
    this.globalChannel = consumer.subscriptions.create(
      { channel: "UserQueueChannel", user_queue: this.userQueueId },
      {
        received: (data) => {
          if (data.queue_status) {
            this.onQueueStatus(data.html);
          }
          if (data.queue_slot) {
            this.onQueueSlotStatus(data);
          }
        },
      }
    );
  }

  onQueueStatus(html) {
    console.log("EntityDashboardController::onQueueStatus");
    $(this.element).find(".user-queue").replaceWith(html);
  }

  onQueueSlotStatus(data) {
    console.error("EntityDashboardController::onQueueSlotStatus", data);
    $(this.element).find(".header").replaceWith(data.rendered_header);
    $(this.element).find(".main").replaceWith(data.rendered_main);
    this.setupTimer();
  }

  togglePause(event) {
    console.log("EntityDashboardController::togglePause", event.target.checked);

    if (event.target.checked) {
      this.slotControlStart();
    } else {
      this.slotControlStop();
    }
  }

  toggleZoom(event) {
    console.log("EntityDashboardController::toggleZoom", this.targetCv);
    $(this.cvTarget).toggleClass("zoomed");
  }

  requestHelp() {
    console.log("EntityDashboardController::requestHelp");
    $.post(`${this.baseUrl}?c=help`, { _method: 'patch'}, (data) => {
      console.log("useSlotControl::SlotControlSendCommand()", data);
    });
  }

  showCV(event) {
    console.log("EntityDashboardController::showCv", event);
    const url = $(event.target).data("url");
    $(this.cvTarget).attr("src", url);
    $(this.cvTarget).toggleClass("d-none", url == null);

    $(this.cvTarget).parent().find(".no-cv").toggleClass("d-none", url != null);
    this.showContent("cv");
  }
}
