import { Controller } from "stimulus";
import { debounce } from "rxjs/operators";
import { interval, Subject } from "rxjs";

export default class extends Controller {
  static targets = ["search"];

  connect() {
    this.search_url = this.data.get("search-url");
    this.element[this.identifier] = this;
    console.log("EntitySearchController::connect", this.search_url);

    this.input = new Subject();
    this.input
      .pipe(debounce(() => interval(300)))
      .subscribe(() => this.search());
  }

  search() {
    console.log("EntitySearchController::search()", this.search_url);
    var url = this.search_url;
    url += "?q=" + this.searchTarget.value;

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  inputChanged() {
    this.input.next();
  }
}
