// app/javascript/channels/chat_channel.js
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("TurboTabs::connect");
  }

  click_tab(event) {

    const url = $(event.currentTarget).data("url");
    console.log("TurboTabs::click_tab", url);
    $(this.element).find("#tab-content").attr("src", url);

  }
}
