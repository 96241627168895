import { Controller } from "stimulus";
/**
 */
export default class extends Controller {
  connect() {
    // console.log("ChatController::connect()");

    // const id = $("#conference").data("navigation-conference");
    // const actid = $("#actchat").data("id");
    // if (actid != id) {
    //   $("#direct-frame").attr("src", `/render_chat/${id}`);
    // }
  }
}
