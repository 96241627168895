import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cv", "name", "contact"];

  connect() {
    console.log("JobInterviewReviewController");

    this.min_rating = this.data.get("rating");
    this.load_more_url = this.data.get("load-more-url");

    // remove the embed to be used as clone
    this.template = $(this.cvTarget).find("embed").clone();

    this.select_subscription_element($(this.element).find(".record:first"));

    $(document).bind("keyup", (e) => {
      console.log(e.which);

      // Maping for numeric keys "normal" + numpad
      const keyMap = {
        97: 1,
        49: 1,
        98: 2,
        50: 2,
        99: 3,
        51: 3,
        100: 4,
        52: 4,
        101: 5,
        53: 5,
        48: 0,
        96: 0,
      };

      if (
        (e.which >= 96 && e.which <= 102) ||
        (e.which >= 48 && e.which <= 53)
      ) {
        this.rate_current(keyMap[e.which]);
      }
    });
  }

  clickNext() {
    console.log("JobInterviewReviewController::clickNext");
    this.activateNext();
  }

  clickPrev() {
    console.log("JobInterviewReviewController::clickPrev");
    this.activatePrev();
  }

  // Check if there are elements which can be hidden, abive the active element we only want to keep
  // 3 elements
  remove_old() {
    var old_count = $(this.element).find(".record.active").prevAll().length;
    console.log("JobInterviewReviewController::remove_old", old_count);
    while (old_count-- > 3) {
      $(this.element).find(".record:first").remove();
    }
  }

  load_more() {
    $.get(this.load_more_url, (data) => {
      var elements = jQuery.parseHTML(data);
      console.log($(elements));

      var act = $(this.element).find(".record").length;
      var toAdd = 10 - act;

      $(elements).each((_i, element) => {
        if ( $(element).hasClass("record") && $(this.element).find("#" + $(element).attr("id")).length == 0) {
          $(this.element).find(".records").append(element);
          toAdd -= 1
        }
        if (toAdd === 0) {
          return;
        }
      });
    });
  }

  // Click on a user, select as active and show its CV
  select_subscription(event) {
    this.select_subscription_element($(event.currentTarget));
  }

  // Show the given subscription element, copy the job description and set the CV src
  select_subscription_element(record) {
    $(this.element).find(".record").removeClass("active");
    record.addClass("active");

    // Show the job description
    $(this.element).find("header").html(record.find(".job-description").html());

    // Show the CV
    const clone = this.template.clone();
    $(clone).attr("src", record.data("url"));
    $(this.cvTarget).find("embed").replaceWith(clone);
  }

  rate_current(rating) {
    console.log("JobInterviewReviewController::rate_current: " + rating);
    let active = $(this.element).find(".record.active");

    // Reduce counter of actual rating of this element
    console.log("Actual", active.data("rating"));
    var dec = $(this.element).find(`.count-${active.data("rating")}`)
    dec.html( parseInt(dec.html()) - 1)

    dec = $(this.element).find(`.count-${rating}`)
    dec.html( parseInt(dec.html()) + 1)
    
    let url = active.data("rating-url").slice(0, -1) + rating;
    console.log("JobInterviewReviewController::rate_current: " + url);

    let c = "maybe";
    if (rating < this.min_rating) c = "no";
    if (rating > this.min_rating) c = "yes";

    $.get(url, function (data) {
      active.find(".rating").replaceWith(data);
      active.removeClass("rating-yes");
      active.removeClass("rating-no");
      active.removeClass("rating-maybe");
      active.addClass("rating-" + c);
    });

    // Increase counter of actual rating of this element

    this.activateNext();
  }

  activateNext() {
    let next = $(this.element).find(".record.active").next();

    if (next.length > 0) {
      this.select_subscription_element(next);
      next[0].scrollIntoView({behavior: "smooth"});
    }
  }

  activatePrev() {
    let next = $(this.element).find(".record.active").prev();

    if (next.length > 0) {
      this.select_subscription_element(next);
      next[0].scrollIntoView({behavior: "smooth"});
    }

  }

  rate(event) {
    console.log("JobInterviewReviewController::rate");

    let rating = $(event.target).closest(".btn").data("rating");
    console.log("JobInterviewReviewController::rate: " + rating);
    this.rate_current(rating);
  }

  skip() {
    // console.log("JobInterviewReviewController::skip");
    this.activateNext();
  }

  contact(event) {
    let active = $(".records li.active");
    let url = active.data("mail-url");
    // console.log("JobInterviewReviewController::contact");
    this.open_mail_popup(url);
  }

  deny_cv(event) {
    let active = $(".records li.active");
    let url = active.data("mail-url");
    let template = active.data("cv-template");
    // console.log("JobInterviewReviewController::deny_cv");
    this.open_mail_popup(url + "&t=" + template);
  }

  open_mail_popup(url) {
    $.featherlight(url + " form", {
      afterOpen: () => {
        $(".html-editor").trumbowyg({
          btns: [["strong", ["formatting"], ["link"]]],
        });
      },
    });
  }
}
