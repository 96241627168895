import { Controller } from "stimulus";

/**
 * Controller for the contry / region select boxes
 */
export default class extends Controller {
  connect() {
    this.select = $(this.element).find("select");
    console.log("CountryRegions::connect()", this.select.attr("id"));

    if (this.select.hasClass("country-select")) {
      this.setupCountry();
    }
    if (this.select.hasClass("region-select")) {
      this.setupRegion();
    }
  }

  setupCountry() {
    this.select.on("change", () => {

      const country = this.select.val();
      $.ajax({
        url: "/domain_data/regions",
        data: { country: country },
        type: "GET",

        success: function (data) {
          const regionSelect = $(".region-select");
          if (regionSelect.length > 0) {
            regionSelect.data("country", country).html($(data).children());
            regionSelect[0].selectedIndex = 0;
          }
        },
      });
    });
  }

  setupRegion() {
    this.select.on("change", () => {
      $.ajax({
        url: "/domain_data/sub_regions",
        data: {
          country: this.select.data("country"),
          region: this.select.val(),
        },
        type: "GET",

        success: function (data) {
          const subregionSelect = $(".subregion-select");
          if (subregionSelect.length > 0) {
            subregionSelect.html($(data).children());
            subregionSelect[0].selectedIndex = 0;
          }
        },
      });
    });
  }
}
